import React, {useState, useEffect, useRef} from 'react'
import PageHeader from '../../component/pageheader'
import {  LoadEffect } from '../../component/loader'
import {Alerts} from '../../component/notify'
import {config, ImagesUrl, API_URL, API_IMAGE} from '../../component/include'
import axios from 'axios'
import {  staffCode} from '../../component/include'
import { Link, useParams } from 'react-router-dom'
import { timeSince } from '../../component/globalFunction'
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [errors, setErrors] = useState({});
	const theme = 'snow';
    // const theme = 'bubble';
    const [staff, setStaff] = useState([]);
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'
          ]
      ],
    };
  
const [reply, setReply] = useState([])
const [image, setImage] = useState([])
    const placeholder = 'Enter your message...';
  
    const formats =  ['bold', 'italic', 'underline', 'strike'  ];
  
    const {quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    const [content, setContent] = useState([])
	
	
    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select id, code, subject, senderType, priority, updated_at, description, senderEmail, senderImage, senderName, created_at, fileUrl, ticketID, status, isNew, department, 'false' as ticketStatus from tbl_tickets where messageType ='main' and ticketID = '" +params.code+"' limit 1";
          
        const fd = new FormData();
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{ 
			if(result.data.data.length!==0){
            setContent(result.data.data)
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,  isLoading: false });
        }) 

  }



  const fetchReply =()=>{
   
    var sql ="Select id, code, subject, priority, senderType, description, updated_at, senderEmail, senderImage, senderName, created_at, fileUrl, ticketID, status, isNew, department, 'false' as ticketStatus from tbl_tickets where messageType ='reply' and ticketID = '" +params.code+"' order by ID ASC";
      
    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){
        setReply(result.data.data)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })

}



const AddMoreFile =()=>{

    var input = document.createElement("input");
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'passport'+image.length);
    input.setAttribute('id', 'passport'+image.length);
    input.className = "form-control mt-2"; 
    input.accept="image/*"
    var parent = document.getElementById("moreFileDiv");
    parent.appendChild(input);
    let allImg = image
                allImg.push({file:'', name:'passport'+image.length})
                    setImage(allImg)
}



function validateImage(id) {
   
    var file = document.getElementById(id).files[0];

    var t = file.type.split('/').pop().toLowerCase();
    if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
        
    Alerts('Error!', 'danger', 'Please select a valid image file')
        document.getElementById(id).value = '';
        return false;
    }

    var FileSize = file.size / 1024 / 1024; // in MB

    if (FileSize > 1) {
        Alerts('Error!', 'danger', 'Max Upload size is 1MB only')
        document.getElementById(id).value = '';
        return false;
    }
    return true; 
}


const handleFetchStaff =()=>{
    var sql ="Select staffName, email, passport  from tbl_staff where code ='"+staffCode+"' "; 
    const fd = new FormData();
fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>setStaff(result.data.data[0]))
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


  const handleSubmit = event =>{
    event.preventDefault(); 
     
    let imageList = []
    
    for(var i=0; i<image.length; i++){
        var file = document.getElementById(image[i].name)
        if(file.value!=='' && validateImage(image[i].name)===true){
            imageList.push({imageUrl:document.getElementById(image[i].name).files[0], name:'t'+Math.random().toString(36).substring(2,9)+'.png' }) 
             
        }else if(file.value!=='' && validateImage(image[i].name)===false){
            
            errors[image[i].name] = 'Max Upload size is 1MB only'
        }
    }


    if(quill.root.innerHTML==="<p><br></p>"){
        errors.message = 'Please enter message';
    }

    setErrors(errors)

    if(Object.keys(errors).length === 0){

        const fd = new FormData(); 
        let link ='[]';
        if(imageList.length!==0){
        link = imageList.filter(item=>item.imageUrl!=='').map(item=>item.name)
             for(let k in imageList){
                if(imageList[k].imageUrl!=='')
                fd.append('imageUrl[]', imageList[k].imageUrl);
            };  
    }
    

    fd.append('senderImage', "staff/"+staff.passport)
    fd.append('senderType', "Staff")
    fd.append('senderCode', staffCode)
    fd.append('fileUrl', imageList.length!==0?JSON.stringify(link,2, null):link)
    fd.append('messageType', "reply")
    fd.append('ticketStatus', "Answered")
    fd.append('message', String(quill?.root.innerHTML));

        fd.append('ticketID', content[0].ticketID)
        fd.append('subject', content[0].subject)
        fd.append('department', content[0].department)
        fd.append('priority', content[0].priority)
        fd.append('ticketCode', content[0].code)
        
      fd.append('username', staff.email)
      fd.append('familyName', staff.staffName)
  
   setNotice({...notice,  isLoading: true}) 
   let url = API_URL+'/ticket/reply';
        axios.post(url, fd, config)
        .then(response =>{
          if (response.data.status ===200){
              Alerts('Saved!', 'success', response.data.message)

          } else{
              setErrors({...errors, errorMessage: JSON.stringify(response.data)})
      }  
      })
      .catch((error)=>{
          setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
      })
      .finally(()=>{
        setNotice({...notice, isLoading: false}) 
      window.location.reload()
      })  
      
        } 
} 


useEffect(()=>{
	fetchReply()
    AddMoreFile()
    handleFetchStaff()
	fetchContent()
},[]);	
	
        return (<>
            
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
				<PageHeader title="Tickets">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>
                    <li className="breadcrumb-item"><a href="/communication/tickets">Tickets</a> </li>
                    </PageHeader>

{/* The Page Body start here */}
<div className="page-body">


                <div className="card">
<div className="card-block email-card">
    <div className="row">
        <div className="col-lg-12 col-xl-3">
            <div className="user-body">
               
			    <div className="card feed-card">
<div className="card-header">
    <h5>   Ticket Information</h5>
</div>

{content.length!==0?content.map((item, index)=><div key={index} className="card-block">
    
<div className="row m-b-25">
              
              <div className="col">
                  <h6 className="m-b-5">Requestor</h6>
                  <p className="text-muted m-b-0">{item.senderName}</p>
                  <p className="text-muted m-b-0"><i className="fa fa-folder-open"></i>Owner</p>
              </div>
          </div>


            <div className="row m-b-25">
              
                <div className="col">
                    <h6 className="m-b-5">Department</h6>
                    <p className="text-muted m-b-0">{item.department}</p>
                </div>
            </div>
            <div className="row m-b-25">
               
                <div className="col">
                    <h6 className="m-b-5">Submitted</h6>
                    <p className="text-muted m-b-0"><i className="feather icon-clock m-r-10"></i>{timeSince(new Date(item.created_at))}</p>
                </div>
            </div>
          
            <div className="row m-b-25">
               
                <div className="col">
                    <h6 className="m-b-5">Last Updated</h6>
                    <p className="text-muted m-b-0"><i className="feather icon-clock m-r-10"></i>{timeSince(new Date(item.updated_at))}</p>
                </div>
            </div>


            <div className="row m-b-25">
               
               <div className="col">
                   <h6 className="m-b-5">Priority / Status</h6>
                   <p className="text-muted m-b-0">{item.priority }  <span className={`icofont icofont-star 
                                                ${item.status==='Open'?
                                                'text-success':item.status==='Closed'?
                                                'text-danger':item.status==='Answered'?
                                                'text-warning':'text-primary'} `}> {item.status}</span></p>
               </div>
           </div>

            <div className="text-center">
<Link to="/communication/tickets" className="b-b-primary text-primary">  View all Tickets</Link>
            </div>
</div>):''}
</div>
            </div>
        </div>

<div className="col-lg-12 col-xl-9">
<div className="card z-depth-0">
            <div className="card-block">
   
   {content.length!==0?content.map((item, index)=> <div className="mail-body" key={index}>
        <div className="mail-body-content email-read">
            <div className="card">
                <div className="card-header">
                    <h5>{item.subject}</h5>
                    <h6 className="f-right">{timeSince(new Date(item.created_at))}</h6>
                </div>
                <div className="card-block">
                    <div className="media m-b-20">
                        <div className="media-left photo-table">
                            <a href="#">
                                <img className="media-object img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${ImagesUrl}/${item.senderImage}`}  alt={item.senderName} />
                            </a>
                        </div>
                        <div className="media-body photo-contant">
                            <a href="#">
                                <h6 className="user-name txt-primary">{item.senderName +' ('+item.senderType+')'}</h6>
                            </a>
                            <a className="user-mail txt-muted" href="#">
                                <h6>From:<span >{item.senderEmail}</span></h6>
                            </a>
                            <div>
                                <p className="email-content" dangerouslySetInnerHTML={{__html:item.description}}></p> 
                            </div>

							
                            <div className="m-t-15">
								{String(item.fileUrl) !== "[]"?
                               <div> <i className="icofont icofont-clip f-20 m-r-10"></i>Attachments <b>({JSON.parse(item.fileUrl).length})</b></div>:''}
                                <div className="row mail-img">


								{String(item.fileUrl) !== "[]"?JSON.parse(item.fileUrl).map((img, id)=>
                                    <div className="col-sm-4 col-md-2 col-xs-12" key={id}>
                                        <a href="#"><img className="card-img-top img-fluid img-thumbnail" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${API_IMAGE}/tickets/${img}`}    alt="Card image cap" /></a>
                                    </div>):''}
                                </div>
                            </div>
							</div>
                    </div>
                </div>
            </div>
        </div>
    </div>):''}






    {reply.length!==0?reply.map((item, index)=> <div className="mail-body" key={index} >
        <div className="mail-body-content email-read" >
            <div className="card" style={{ borderRadius:'10px',  backgroundColor:`${index%2==0?'#d5d4d8':''}` }}>
        
                <div className="card-block">
                    <div className="media ">
                        <div className="media-left photo-table">
                            <a href="#">
                                <img className="media-object img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${ImagesUrl}/${item.senderImage}`}  alt={item.senderName} />
                            </a>
                        </div>
                        <div className="media-body photo-contant">

                    <h6 className="f-right">{timeSince(new Date(item.created_at))}</h6>
                            <a href="#">
                                <h6 className="user-name txt-primary">{item.senderName +' ('+item.senderType+')'}</h6>
                            </a>
                            <a className="user-mail txt-muted" href="#">
                                <h6>From:<span >{item.senderEmail}</span></h6>
                            </a>
                            <div>
                                <p className="email-content" dangerouslySetInnerHTML={{__html:item.description}}></p> 
                            </div>

							
                            <div className="m-t-15">
								{String(item.fileUrl) !== "[]"?
                               <div> <i className="icofont icofont-clip f-20 m-r-10"></i>Attachments <b>({JSON.parse(item.fileUrl).length})</b></div>:''}
                                <div className="row mail-img">


								{String(item.fileUrl) !== "[]"?JSON.parse(item.fileUrl).map((img, id)=>
                                    <div className="col-sm-4 col-md-2 col-xs-12" key={id}>
                                        <a href="#"><img className="card-img-top img-fluid img-thumbnail" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/no.jpg'}} src={`${ImagesUrl}/tickets/${img}`}    alt="Card image cap" /></a>
                                    </div>):''}
                                </div>
                            </div>
							</div>
                    </div>
                </div>
            </div>
        </div>
    </div>):''}


        <div className="mail-body">
<h4 className='bold'>Your Reply</h4>
                <div className="mail-body-content">
                    <form>
                    <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 bold">Name
                                    <input type="text" className="form-control" name="senderName" value={staff.staffName} disabled  />
                                </div>
                                <div className="col-md-6 bold"> Email Address
                                    <input type="email" className={errors.senderEmail ? 'form-control form-control-danger' : 'form-control'} name="senderEmail" disabled value={staff.email}   />
                                    <span style={{color:'red'}}>{errors.senderEmail}</span>
                                </div>
                            </div>
                        </div>

                       
                        <div className="form-group "><b>Message</b>
                        
                       
<div style={{ width: '100%', height:200 }}>
                        <div ref={quillRef} />

                        </div>
                
                        </div>

                        <br/>
                        <span style={{color:'red'}}>{errors.message}</span>
                        <div style={{ height: 10 }}></div>
                    </form>
                </div>


        </div>
        <div className="row">
    <div className=" col-md-9 ">

    <div className=" bold">Attachments (<span className='text-red'>Allowed: .jpg, .jpeg, .png, Max: 1Mb</span>)
                            
                            
                            <div id='moreFileDiv'>

                            </div>
                        </div> 
    </div>

   
    <div className=" col-md-3"><br/>
    <button type='button' onClick={AddMoreFile} className="btn btn-inverse">+ Add More</button>
        </div>

        </div>
        <div className=" col-md-12 text-center"><br/>
        <button type='button' className="btn btn-success" onClick={handleSubmit}>Submit</button>&nbsp;&nbsp;
        <button type='button' className="btn btn-secondary" onClick={()=>window.open('/communication/tickets', '_self')}>Cancel</button>
    
            </div>

			<div style={{ height: 60 }}></div>




			</div>
            </div>

</div>
    </div>
</div>
</div>

</div>
				
          {/* The Page Body close here */}

            </div>
            </div>

</> 

 );
        
}

export default React.memo(Edit) 