import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, ImagesUrl, ServerUrl, Token, config, schoolName} from '../component/include'
import Select from 'react-select'
import { getClassField, getClassName, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ExamSummary =()=> {
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
        const [errors, setErrors] = useState({});
       const [examtype, setExamtype]=useState([])
    const [allResult, setAllResult]=useState([])

const [result, setResult] = useState({
        studentClass:[],
        examtype:[]
    })

    const [stat, setStat] = useState({
        male:0,
        female:0,
        total:0
    })
  

    const fetchExamType =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
        
            setExamtype(result.data.examType)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }

const  handleFetchClass =()=>{

    if(result.examtype.length!==0)
    setNotice({...notice, isLoading: true}) 
    const fd = new FormData();
    fd.append('jwt', Token)
       let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
       axios.post(url, fd, config)
       .then(res =>{
  
               let urls = API_URL+'/exam/summary/'+result.examtype.value;
               axios.get(urls, config).then(response=>{
                      let sec = getAllSection(res.data)
                    var final = sortResult(response.data.data, sec, res.data)
if(final.length>0){
    var female = final.reduce((accumulator, currentValue)=>Number(accumulator)+Number(currentValue.female), 0)
    var male = final.reduce((accumulator, currentValue)=>Number(accumulator)+Number(currentValue.male), 0)



 setAllResult(final.sort((a, b) =>(Number(a.order) > Number(b.order)) ? 1 : -1)) 
                 setStat({
                    female:female,
                    male:male,
                    total:male+female
                })
            }

            })
          })
       .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))).finally(()=>{
        setNotice({...notice, isLoading: false}) 
       }) 
           
   }

   const getAllSection =(school)=>{
    let section = []
    var newClass = [...school];
  
     for (var i in newClass){
            if(newClass[i].section[0].sname === ''){   
            section.push({classID:newClass[i].ID, sectionID:newClass[i].ID})
            } else{ 
                for(var k in newClass[i].section){
                    section.push({classID:newClass[i].ID, sectionID:newClass[i].section[k].ID})
                    }
        } 
    } 
  
    return section
  }

  const sortResult =(data, section, classes)=>{
    let result = []
    for(var k in section){

    for(var i in data){
        if(data[i].sectionID===section[k].sectionID){
        result.push({label:String(getClassName(section[k].classID,section[k].sectionID, classes)), male:data[i].Male, female:data[i].Female, total:Number(data[i].Male)+Number(data[i].Female), order:getClassField(section[k].classID, classes, 'order')})
      }
    }
      }
return result;
  }

  const handleSelect =(option, action)=>{     
          
    setResult({...result, [action.name]:option});
}

   useEffect(()=>{
    fetchExamType()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Exam Summary Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="#"> Academic</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Exam Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

          

<div className="row  no-print">
        

        <div className="col-sm-12">
    
        <div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-edit" ></i> Exam Schedule</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div><div className="card-block">
            <div className="card z-depth-0">
            <div className="card-block">
            
            <div className="row">
     
        
            <section className="col-md-12">
                <div className="form-group">
                <label> Search by Examination <span style={{color:'red'}}>*</span></label>
                <Select  options={examtype&&examtype.map((list, idx)=> {
                                    return {key:idx, value: list.examCode, label: list.exam }
                                    })
                            } 
                            
    onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>
    
        
                    </div>
            
            </section>
            </div>
    
            <div className="row">
            <div className="col-md-12 ">  
        <button type="button" id="submit" onClick={handleFetchClass} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search </button>
                </div>
                </div>	             
                    
                                </div>
                                </div>
                                </div></div>
    </div>
    
    </div>


    {allResult.length!==0?<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
       <tr>
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{result.examtype.label} STUDENT POPULATION SUMMARY  </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">
<table className="table table-bordered  table-full-width">
       <tbody>
               
                <tr>
                    <td >Total Student: <b>{stat.total}</b></td>
                    <td >Male: <b> {stat.male}</b></td>
                    <td >Female: <b> {stat.female}</b></td>
                </tr>
            </tbody>
       </table>

        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                <th>Class</th>
                    <th>No. of Male</th>
                    <th>No. of Female</th>
                    <th>Total</th>

                </tr>
            </thead>
            <tbody>{allResult.length!==0? allResult.map((row, idx)=>
            
            <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{row.label}</td>
                    <td>{row.male}</td>
                    <td>{row.female}</td>
                    <td>{row.total}</td>
                </tr>): <TableLoader/>}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/exam_summary.php?token='+userToken} target='_blank' className='pull-right' >
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='allResult' value ={JSON.stringify(allResult, null, 2)} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
               
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:[]}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExamSummary) 